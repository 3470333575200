import React from "react"
import SEO from "./../components/seo"
import HomePage from "./homePage"

const IndexPage = () => (
  <>
    <SEO />
    <HomePage />
  </>
)

export default IndexPage
