import slugify from "slugify"

export const removeJointWords = (txt: string) => {
  const jointWordsArr = ["on", "with", "and", "in"]
  const expStr = jointWordsArr.join("|")
  return txt
    .replace(new RegExp("\\b(" + expStr + ")\\b", "gi"), " ")
    .replace(/\s{2,}/g, " ")
    .replace(/ & /g, " ")
}

export const slug = (text: string) =>
  slugify(removeJointWords(text), { lower: true })
