import {
  FilterOption,
  PaneFilter,
} from "@rily/components/lib/src/types/filters"
import { uniq } from "lodash"
import { useContext, useEffect, useState } from "react"
import { AppContext } from "../context"
import { Constraint } from "../types/constraint"
import { fetchConstraints } from "./fetchConstraints"

// TODO:
// Right now we're just hardcoding all filters.
// Eventually, these defaults should be CMS controlled + curated
// Any filtering on the recipes should then use recipe facets to generate the list, ordered by most matches.
// ex: If we filter on "vegetarian", the ingredients should be 10 items that will produce the most possible results.
//     If we filter further, the quick filters should adapt to list filters that will continue to produce the most results

export const useRecipeFilters = () => {
  const { params, constraints, setConstraints } = useContext(AppContext)
  const [ingredientFilters] = useState<PaneFilter>(defaultIngredientFilters)
  const [constraintFilters, setConstraintFilters] = useState<PaneFilter>({
    title: "Conditions & diets",
    items: [],
  })

  const [selectedFilters, setSelectedFilters] = useState<FilterOption[]>([])

  // Use effect here only so we can fetch constraints from remote
  useEffect(() => {
    const fetchConstraintFilters = async () => {
      const filters = await defaultConstraintFilters()
      const remoteConstraints = (await fetchConstraints()).map(constraint =>
        formatConstraint(constraint)
      )
      setConstraintFilters(filters)
      setConstraints(remoteConstraints)
    }

    fetchConstraintFilters()
  }, [])

  useEffect(() => {
    const selectedIngredients = Array.isArray(params.ingredients)
      ? uniq(
          params?.ingredients?.map(param =>
            findSelectedFilter("INGREDIENT", param, ingredientFilters)
          )
        )
      : []

    const selectedConstraints = Array.isArray(params.constraints)
      ? params?.constraints?.map(param =>
          findSelectedFilter("CONSTRAINT", +param, {
            title: "",
            items: constraints,
          })
        )
      : []

    if (selectedConstraints && selectedIngredients) {
      setSelectedFilters([...selectedConstraints, ...selectedIngredients])
    }

    if (selectedConstraints && !selectedIngredients) {
      setSelectedFilters([...selectedConstraints])
    }

    if (!selectedConstraints && selectedIngredients) {
      setSelectedFilters([...selectedIngredients])
    }
  }, [params])

  return {
    filters: [ingredientFilters, constraintFilters],
    selectedFilters,
    clearSelectedFilters: () => setSelectedFilters([]),
  }
}

const findSelectedFilter = (
  type: "INGREDIENT" | "CONSTRAINT",
  value: number | string,
  filters: PaneFilter
): FilterOption => {
  // This needs a serious refactor downstream, once we move to CMS
  // We should look at parent ingredient, vs every child ingredient
  if (type === "INGREDIENT") {
    const selected = value.toString().split(",")
    return filters.items.find(filter => {
      const hasMatch = selected.filter(select => {
        if (Array.isArray(filter.value)) {
          return filter.value?.indexOf(+select) >= 0
        }
      })

      return hasMatch.length
    })
  }

  if (type === "CONSTRAINT") {
    return filters.items.find(filter => {
      return filter.value == value
    })
  }

  return null
}

const defaultIngredientFilters: PaneFilter = {
  title: "Main ingredients",
  items: [
    {
      type: "INGREDIENT",
      value: [760, 784, 785, 820, 848, 998, 1039, 1112, 1113],
      label: "Chicken",
    },
    {
      type: "INGREDIENT",
      value: [437, 707, 712, 1052],
      label: "Chickpeas",
    },
    {
      type: "INGREDIENT",
      value: [162, 622, 1012],
      label: "Flax",
    },
    {
      type: "INGREDIENT",
      value: [120, 327, 328, 329, 330, 331, 950, 951, 973, 1009, 1062],
      label: "Onion",
    },
    {
      type: "INGREDIENT",
      value: [
        379, 380, 383, 384, 385, 386, 387, 388, 389, 390, 393, 394, 974, 975,
        1013, 1058, 1094,
      ],
      label: "Mushroom",
    },
    {
      type: "INGREDIENT",
      value: [312, 313, 314, 315, 966, 1032, 1065],
      label: "Kale",
    },
    {
      type: "INGREDIENT",
      value: [650, 651, 652, 1110],
      label: "Buckwheat",
    },
    {
      type: "INGREDIENT",
      value: [928, 929, 1120],
      label: "Scallops",
    },

    {
      type: "INGREDIENT",
      value: [
        539, 540, 541, 542, 543, 544, 545, 546, 547, 548, 549, 550, 551, 552,
        553, 788, 789, 829,
      ],
      label: "Egg",
    },
  ],
}

const defaultConstraintFilters = async (): Promise<PaneFilter> => {
  const remoteConstraints = await fetchConstraints()
  const curatedConstraints = [17, 23, 14, 9, 3, 20, 22, 33, 4]

  return {
    title: "Conditions & diets",
    items: remoteConstraints
      .filter(constraint => curatedConstraints.includes(constraint.id))
      .map(formatConstraint),
  }
}

const formatConstraint = (constraint: Constraint): FilterOption => {
  return {
    value: constraint.id,
    label: constraint.title
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
      .join(" ")
      .replace("_", "'"),
    type: "CONSTRAINT",
    variant: constraint.variant,
  }
}
