export const createQueryString = args => {
  return (
    "?" +
    Object.entries(args)
      .map(([key, value]) => {
        return key + "=" + value
      })
      .join("&")
  )
}
