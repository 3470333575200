import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import { RecipeListingPage } from "@rily/components"
import Landing from "../../components/landing"
import { useParams } from "../../hooks/useParams"
import { useRecipes } from "../../hooks/useRecipes"
import { useRecipeFilters } from "../../hooks/useRecipeFilters"
import { SortOption } from "@rily/components/lib/src/components/molecules/RecipeListHeader/RecipeListHeader"
import { generateFilterOptionsFromRecipes } from "../../utils/recipe"
import { Constraint } from "../../types/constraint"
import { fetchConstraints } from "../../hooks/fetchConstraints"
import { isEqual } from "lodash"
import { fetchRecipe } from "../../hooks/fetchRecipe"
import { useWindowSize } from "../../hooks/useWindowSize"

const HomePage: React.FC<{}> = () => {
  const windowSize = useWindowSize()
  // Watches the URL for param updates
  const [params, setParams, clearParam] = useParams()

  // Watches the params and fetches recipes on change
  const { recipes, facets, meta } = useRecipes()

  // Fetch side pane filters
  const { filters, selectedFilters, clearSelectedFilters } = useRecipeFilters()

  const [constraints, setConstraints] = useState<Constraint[]>([])

  useEffect(() => {
    const getConstraints = async () => {
      const remoteConstraints = await fetchConstraints()
      setConstraints(remoteConstraints)
    }

    getConstraints()
  }, [])

  useEffect(() => {
    const filterChangeHandler = event => handleFilterChange(event.detail)
    const filterAddHandler = event =>
      handleFilterChange([event.detail, ...selectedFilters])
    const filterRemoveHandler = event => {
      const toKeep = selectedFilters
        .filter(filter => filter.type === event.detail.type)
        .filter(filter => {
          return !isEqual(event.detail.value, filter?.value)
        })

      const otherFilters = selectedFilters.filter(
        filter => filter.type !== event.detail.type
      )

      // if we remove the last param, just clear the key
      if (toKeep.length < 1) {
        clearParam(event.detail.type.toLowerCase() + "s")
      }

      handleFilterChange([...toKeep, ...otherFilters])
    }

    window.addEventListener("Filter:Change", filterChangeHandler)
    window.addEventListener("SingleFilter:Add", filterAddHandler)
    window.addEventListener("SingleFilter:Remove", filterRemoveHandler)

    return () => {
      window.removeEventListener("Filter:Change", filterChangeHandler)
      window.removeEventListener("SingleFilter:Add", filterAddHandler)
      window.removeEventListener("SingleFilter:Remove", filterRemoveHandler)
    }
  }, [selectedFilters])

  const handleNav = (page: number) => {
    // Handle page params separately, so that when you paginate you keep filters
    // ex: Select Vegetarian and Onion, you should be able to go to page 2
    //     But if a user adds another filter, we want to start at page 1 of the new set
    setParams({ ...params, page })
  }

  const getSortOptions: (setParams) => SortOption[] = setParams => [
    {
      titleKey: "button.sort_by_latest",
      sortParam: "createdAt-desc",
      isDefault: true,
      sortHandler: () => setParams({ ...params, sort: "createdAt-desc" }),
    },
    {
      titleKey: "button.sort_by_cook_time",
      sortParam: "cookTime-asc",
      sortHandler: () => setParams({ ...params, sort: "cookTime-asc" }),
    },
  ]

  const handleFilterChange = filters => {
    const values = filters?.reduce((acc, filter) => {
      if (!filter?.type || !filter?.value) {
        console.error(`invalid filter ${JSON.stringify(filter)}`)
        return acc
      }

      // Param names on server are plural
      const paramName = filter.type.toLowerCase() + "s"
      const paramValue = filter.value.toString()

      if (acc[paramName]) {
        acc[paramName].push(paramValue)
      } else {
        acc[paramName] = [paramValue]
      }

      return acc
    }, {})

    // We want to maintain sort anytime we change filter values
    setParams({ ...values, ...(params.sort && { sort: params.sort }) })
  }

  return (
    <>
      <Landing />
      <RecipeListingPage
        methods={{
          fetchQuickViewRecipe: (id: string) => fetchRecipe({ id }),
        }}
        data={{
          recipeList: {
            loadingRecipes: recipes.length === 0,
            recipes,
            sortOptions: getSortOptions(setParams),
            disableQuickView: windowSize?.width < 768,
            currentSort: params.sort,
            pagination: {
              // TODO: fix pagination totalPage on server
              numberOfPages: meta.count / 40,
              onPage: meta.page,
              clickHandler: handleNav,
            },
          },
          filters: {
            paneFiltersList: filters,
            selectedFilters: selectedFilters,
          },
          filterBar: {
            allDropdowns: ["diets", "conditions"].map(variant =>
              generateFilterOptionsFromRecipes(facets, constraints, variant)
            ),
            selectedFilters: selectedFilters,
            resetRecipes: () => {
              clearSelectedFilters()
              navigate(`${window.location.pathname}`, {
                state: { shouldScroll: true },
              })
            },
          },
          filterTabNames: ["diet", "condition"],
        }}
      />
    </>
  )
}

export default HomePage
