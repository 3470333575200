import axios from "axios"

const { GATSBY_API_URL } = process.env

export const fetchData = async <T>(path: string): Promise<T> => {
  try {
    const { data } = await axios(`${GATSBY_API_URL}${path}`)
    return data
  } catch (e) {
    console.error(e)
  }
}
