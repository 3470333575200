import { RecipeListingRecipe, RecipeFacets } from "../types/recipe"
import { fetchData } from "./fetch"
export interface RecipeListingPage {
  recipes: RecipeListingRecipe[]
  facets: RecipeFacets
}

interface PaginationMeta {
  count: number
  totalPages: number
  page: number
}

export type WithPagination<T> = {
  [key in keyof T]: T[key]
} & {
  meta: PaginationMeta
}

interface RecipeFetchOptions {
  constraints?: number[]
  ingredients?: number[]
  sort?: string
  page?: number
}

export const fetchRecipesRest = async (options?: RecipeFetchOptions) => {
  let url = "recipes?state=published&facets=true"

  if (options?.constraints?.length) {
    if (typeof options.constraints === "string") {
      url += `&constraints=${options.constraints}`
    } else {
      url += `&constraints=${options.constraints.join(",")}`
    }
  }

  if (options?.ingredients?.length) {
    url += `&ingredients=${options.ingredients.join(",")}`
  }

  if (options?.sort) {
    url += `&sort=${options.sort}`
  }

  if (options?.page) {
    url += `&page=${options.page}`
    return fetchData<WithPagination<RecipeListingPage>>(url)
  }

  return fetchData<RecipeListingPage>(url)
}
