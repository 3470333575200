const isCloudinaryUrl = (
  imageUrl = "https://res.cloudinary.com/heat-supply/image/upload/c_fill,h_400,w_300//v1605735552/rily/bianca_buckwheat_noodle_soup2_oc6gjn.jpg"
): boolean => {
  const url = new URL(imageUrl)
  return url.hostname === "res.cloudinary.com"
}

export const resizeImage = (
  imageUrl: string = "https://res.cloudinary.com/heat-supply/image/upload/c_fill,h_400,w_300//v1605735552/rily/bianca_buckwheat_noodle_soup2_oc6gjn.jpg",
  width?: number,
  height?: number
): string => {
  if (isCloudinaryUrl(imageUrl)) {
    //regex replaces http with https
    const secureUrl = imageUrl.replace(/^http:\/\//i, "https://")
    const splitBy = "image/upload"
    const urlParts = secureUrl.split(splitBy)
    const resizeString = `f_auto,c_fill,h_${height ? height : 400},w_${
      width ? width : 300
    }`
    return `${urlParts[0]}${splitBy}/${resizeString}${urlParts[1]}`
  }

  return imageUrl
}
