import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { navigate } from "gatsby"
import { Title, MediumTitle } from "../typography/title"
import Swiper from "react-id-swiper"
import SwiperCore, { Autoplay, Pagination } from "swiper/core"

import accentShape from "../../static/images/landing-accent-shape.svg"
import { resizeImage } from "../../utils/image"

import { fetchFeaturedRecipesGQL } from "../../hooks/fetchFeaturedRecipesGQL"
import { FeaturedRecipesListRecipe } from "../../types/recipe"
import { slug } from "../../server/utils/string"
import { selectContentevent } from "../../utils/analytics"

SwiperCore.use([Autoplay, Pagination])

// swiper js configs
const swiperParams = {
  pagination: {
    el: ".swiper-pagination",
    clickable: true,
  },
  autoplay: {
    delay: 6000,
    disableOnInteraction: false,
    autoplayDisableOnInteraction: false,
  },
  containerClass: "swiper-container",
  wrapperClass: "wrapper-scroll",
  slideClass: "slide-item",
  loop: true,
  spaceBetween: 0,
  centeredSlides: true,
  allowTouchMove: false,
  breakpoints: {
    768: {
      allowTouchMove: true,
    },
  },
}

const LandingRedesign = () => {
  const GQLFeaturedRecipes = fetchFeaturedRecipesGQL()
  const [featuredRecipes, setFeaturedRecipes] =
    useState<Array<FeaturedRecipesListRecipe>>(GQLFeaturedRecipes)

  useEffect(() => {
    require("viewport-units-buggyfill").init()
  })

  const featuredRecipesComponent = featuredRecipes.map((recipe, index) => {
    const handleClick = () => {
      selectContentevent({
        content_type: "homepage_featured_recipe",
        item_id: recipe.id,
        index,
      })
      navigate(`/recipes/${slug(recipe["title"])}`)
    }
    return (
      <Featured key={recipe.id}>
        <FeaturedImage
          bgSrc={"url(" + resizeImage(recipe["image_url"], 2000, 1000) + ")"}
        />
        <FakeLink onClick={handleClick}>
          <FeaturedContent>
            <FeaturedRecipe>{recipe.title}</FeaturedRecipe>
          </FeaturedContent>
        </FakeLink>
      </Featured>
    )
  })

  return (
    <Wrapper>
      <Swiper {...swiperParams}>{featuredRecipesComponent}</Swiper>
      <ContentWrapper>
        <Content>
          <Intro>
            We believe in <Highlight>really good recipes</Highlight> that
            rethink <br /> dietary cooking
          </Intro>
          <img src={accentShape} />
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}

export default LandingRedesign

const FakeLink = styled.p`
  cursor: pointer;
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.yellow};
  display: flex;
  flex-direction: row;

  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;

  .swiper-container {
    height: 100vh;
    overflow: hidden;
  }
  .wrapper-scroll {
    height: calc(100vh + 2px) !important;
    display: flex;
    flex-direction: row;
  }
  .slide-item {
    height: calc(100vh + 2px) !important;
  }
  .swiper-pagination {
    color: white;
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
  }

  .swiper-pagination-bullets {
    bottom: 1rem;
    left: 0;
    width: 100%;
  }
  .swiper-pagination-bullet {
    background: ${props => props.theme.colors.white};
    opacity: 0.2;
    width: 0.6rem;
    height: 0.6rem;
    display: inline-block;
    border-radius: 100%;
  }
  .swiper-pagination-bullet ~ .swiper-pagination-bullet {
    margin-left: 0.5rem;
  }
  .swiper-pagination-bullet-active {
    opacity: 1;
    background: ${props => props.theme.colors.white};
  }
`
const ContentWrapper = styled.div`
  position: absolute;
  height: 50vh;
  width: 50%;
  padding: 46px 55px;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  @media (min-width: 768px) {
    width: 500px;
    padding: 0px;
  }
`
const Content = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  justify-content: center;
  align-items: center;

  img {
    position: absolute;
    z-index: -1;
    right: 0;
    width: 100%;
  }
`
const Intro = styled(Title)`
  margin: 2rem 2rem 4rem 3rem;
  user-select: none;
  font-size: 18px;
  @media (min-width: 460px) {
    font-size: 22px;
    margin: 2rem 3rem 4rem;
  }

  @media (min-width: 768px) {
    font-size: 33px;
    margin: 3rem 5rem 6rem;
  }
`

const Highlight = styled.span`
  display: block;
  color: ${props => props.theme.colors.white};
`

const Featured = styled.div`
  position: relative;
  background-color: ${props => props.theme.colors.yellow};
  height: calc(100vh + 2px);
  width: 100vh;
`
const FeaturedImage = styled.div`
  width: 100vw;
  height: calc(100vh + 2px);
  background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 12.5%,
      rgba(0, 0, 0, 0) 40%
    ),
    ${props => (props.bgSrc ? props.bgSrc : null)};
  background-size: cover;
  background-position: center;

  @media (min-width: 768px) {
    background-image: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.5) 12.5%,
        rgba(0, 0, 0, 0) 25%
      ),
      linear-gradient(180deg, rgba(0, 0, 0, 0.35) 12.5%, rgba(0, 0, 0, 0) 25%),
      ${props => (props.bgSrc ? props.bgSrc : null)};
  }
`
const FeaturedContent = styled.div`
  width: calc(100% - 40px);
  bottom: 35px;
  position: absolute;
  padding: 0 20px 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  z-index: 100;
  @media (min-width: 768px) {
    width: calc(35% - 60px);
    bottom: 28px;
    padding: 0 20px 0 34px;
  }
`
const FeaturedRecipe = styled(MediumTitle)`
  user-select: none;
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  border-bottom: 2px solid;
`
