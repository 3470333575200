import { graphql, useStaticQuery } from "gatsby"
import { FeaturedRecipesListRecipe } from "../types/recipe"

export const fetchFeaturedRecipesGQL = (): FeaturedRecipesListRecipe[] => {
  const data = useStaticQuery(graphql`
    query FeaturedRecipesQuery {
      allFeaturedRecipe(sort: { order: ASC, fields: display_order }) {
        featuredRecipes: edges {
          node {
            id
            recipe_id
            display_order
            image_url
            recipe_data {
              title
            }
          }
        }
      }
    }
  `)
  return data.allFeaturedRecipe.featuredRecipes.map(({ node }) => {
    const {
      id,
      recipe_id,
      display_order,
      image_url,
      recipe_data: { title },
    } = node
    return { id, recipe_id, display_order, image_url, title }
  })
}
