import { useContext, useEffect, useState } from "react"
import { AppContext } from "../context"
import {
  fetchRecipesRest,
  RecipeListingPage,
  WithPagination,
} from "./fetchRecipesRest"

export const useRecipes = () => {
  const [recipes, setRecipes] = useState<
    Partial<WithPagination<RecipeListingPage>>
  >({ recipes: [], meta: { totalPages: 0, page: 1, count: 400 } })
  const { params } = useContext(AppContext)

  // If params change. we want to refetch recipes
  useEffect(() => {
    const fetchRecipes = async () => {
      const recipes = await fetchRecipesRest({ ...{ page: 1 }, ...params })
      setRecipes(recipes)
    }

    fetchRecipes()
  }, [params, window.location.pathname])

  return recipes
}
