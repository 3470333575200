import { Recipe } from "../types/recipe"
import { fetchData } from "./fetch"

interface RecipeFetchOptions {
  id: string
}

export const fetchRecipe = async (options: RecipeFetchOptions) => {
  let url = `recipes/${options.id}`
  const data = await fetchData<any>(url)
  return {
    ...data,
    cookTime: data.cook_time,
    ingredients: data.recipe_ingredient,
  }
}
