import { useContext, useEffect } from "react"
import { pick, mapValues, uniq, isArray, mapKeys } from "lodash"
import qs from "query-string"
import { AppContext, RecipeParams, stringParams, validParams } from "../context"
import { createQueryString } from "../utils/createUrlString"
import { navigate } from "gatsby"

export const useParams = () => {
  const { params, setParams, constraints } = useContext(AppContext)

  // Run once on init
  useEffect(() => {
    // Do not run until we have constraints
    // TODO: Try to figure out a way to remove
    if (!constraints) {
      return
    }

    const initParams = mapValues(
      qs.parseUrl(window.location.href).query,
      value => {
        if (Array.isArray(value)) {
          return value
        }
        return value.split(",")
      }
    )
    generateParams(setParams, initParams)
  }, [constraints])

  // Set handler to navigate on param change
  useEffect(() => {
    if (Object.keys(params).length > 0) {
      // Filter out empty keys to avoid adding empty props, ex: ?constraints=
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, v]: any) =>
          typeof v === "string" || Array.isArray(v) ? v.length > 0 : true
        )
      )

      // We only want to scroll to top of page when we change page,
      // not any other param
      const page = qs.parseUrl(window.location.href).query?.page || 0
      const shouldScroll = params.page && +page !== +params.page

      const qp = createQueryString(filteredParams)

      // shouldScroll flag will force page to scroll back to top
      // Logic for this is in gatsby-browser.js
      navigate(`${window.location.pathname}${qp}`, {
        state: { shouldScroll },
      })
    }
  }, [params, window.location.pathname])

  return [
    params,
    (newValues: RecipeParams) => generateParams(setParams, newValues),
    (paramToClear: keyof RecipeParams) => {
      setParams({
        [paramToClear]: isStringParam(null, paramToClear) ? "" : [],
      })
    },
  ]
}

const generateParams = (setParams, newValues: Partial<RecipeParams>) => {
  if (Object.keys(newValues).length === 0) {
    return
  }

  const validatedNewValues = mapKeys(newValues, (_, key) => key.toLowerCase())

  const validatedParams = mapValues(
    // Grab only valid params
    pick(validatedNewValues, validParams),
    param => {
      if (!param) {
        return
      }

      // then force URL params to be an array
      if (!isArray(param)) {
        const split = param.toString().split(",")
        if (split.length === 1) {
          return split[0].toString()
        }

        return uniq(split.map(v => v.toString()))
      }

      return uniq(param.map(v => v.toString()))
    }
  )

  setParams({ ...validatedParams, ...validatedNewValues })
}

const isStringParam = (
  value: any,
  key: any
): value is typeof stringParams[number] => {
  return stringParams.includes(key)
}
